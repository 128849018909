import { default as bank2PEtwP1qusMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/bank.vue?macro=true";
import { default as bonusp1vIIB6ob9Meta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/bonus.vue?macro=true";
import { default as bank8M9u7Fl1NvMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardTTBny3OqDpMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/card.vue?macro=true";
import { default as codepayualXTiugqnMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay21QGVRIE6SmMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as cryptoH5ziRjjIRDMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/crypto.vue?macro=true";
import { default as ewalletpl4JkUNV6FMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/ewallet.vue?macro=true";
import { default as betNvkUNGdbLLMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/history/bet.vue?macro=true";
import { default as transactionobWtmZ0AFHMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/history/transaction.vue?macro=true";
import { default as historyFm5QYozFNwMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/history.vue?macro=true";
import { default as profileiXLG9QNOxKMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/profile.vue?macro=true";
import { default as trading_45p2p4xARsxcTMOMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/trading-p2p.vue?macro=true";
import { default as bankHnkQBQXRQdMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/withdraw/bank.vue?macro=true";
import { default as card49XBV19KXyMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/withdraw/card.vue?macro=true";
import { default as cryptoeTYVEHFM2oMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountINx95OOlSoMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account.vue?macro=true";
import { default as base_45button1XFZq5UuMuMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downVbGBaBBmviMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radioSBjeRiN3IbMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgYtktoPHe2KMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-img.vue?macro=true";
import { default as base_45inputMKJm0uRzCAMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loadingbkISna7Gq2Meta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modalzsuPio39TVMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputga8YsbxtxFMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationEnVedw7liuMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45bankss2Kkl5cIGMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemSQ9BlR2SZ2Meta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/game-item.vue?macro=true";
import { default as indexiGFlsWvYiKMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/index.vue?macro=true";
import { default as _91type_93hW8m9vxsKzMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/game/[type].vue?macro=true";
import { default as gamevqNmkVqUS3Meta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/game.vue?macro=true";
import { default as _91alias_93ERdWbowrbxMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieu11wczK5PePMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93T1kbZUs5hYMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45danwqSD1eMPDBMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/huong-dan.vue?macro=true";
import { default as indexBEl7wtRUx0Meta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/index.vue?macro=true";
import { default as _91id_93G8vn1DPvBQMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as indexX9X8ZUZyVpMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93K2UFsyZH5MMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as vipM467blDCVtMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/khuyen-mai/vip.vue?macro=true";
import { default as khuyen_45maiFDppUDrR2wMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/khuyen-mai.vue?macro=true";
import { default as _91type_93LrfUv1xYRJMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/livecasino/[type].vue?macro=true";
import { default as indexrV81sYWGrOMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/livecasino/index.vue?macro=true";
import { default as livecasino4clsLugEvXMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/livecasino.vue?macro=true";
import { default as reset_45passwordFkmZWsQX6eMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/reset-password.vue?macro=true";
import { default as _91id_93RytHW1kTvTMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/the-thao/[id].vue?macro=true";
import { default as indexdQf3UM2GkAMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/the-thao/index.vue?macro=true";
import { default as indexQGdGM4CSFJMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/the-thao/lich-thi-dau/chi-tiet/index.vue?macro=true";
import { default as indexmrukvZ6eOXMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_93MyKMMxJJv7Meta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as indexCHLm3jKtm6Meta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93TsVPEZwEHrMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tuceZ9NeqnDZVMeta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/tin-tuc.vue?macro=true";
import { default as verify_45mailTQKLjoi1V7Meta } from "/Users/yoshi/Documents/Project/Brand/ps26-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountINx95OOlSoMeta?.name ?? "account",
    path: accountINx95OOlSoMeta?.path ?? "/account",
    children: [
  {
    name: bank2PEtwP1qusMeta?.name ?? "account-bank",
    path: bank2PEtwP1qusMeta?.path ?? "bank",
    meta: bank2PEtwP1qusMeta || {},
    alias: bank2PEtwP1qusMeta?.alias || [],
    redirect: bank2PEtwP1qusMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bonusp1vIIB6ob9Meta?.name ?? "account-bonus",
    path: bonusp1vIIB6ob9Meta?.path ?? "bonus",
    meta: bonusp1vIIB6ob9Meta || {},
    alias: bonusp1vIIB6ob9Meta?.alias || [],
    redirect: bonusp1vIIB6ob9Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/bonus.vue").then(m => m.default || m)
  },
  {
    name: bank8M9u7Fl1NvMeta?.name ?? "account-deposit-bank",
    path: bank8M9u7Fl1NvMeta?.path ?? "deposit/bank",
    meta: bank8M9u7Fl1NvMeta || {},
    alias: bank8M9u7Fl1NvMeta?.alias || [],
    redirect: bank8M9u7Fl1NvMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardTTBny3OqDpMeta?.name ?? "account-deposit-card",
    path: cardTTBny3OqDpMeta?.path ?? "deposit/card",
    meta: cardTTBny3OqDpMeta || {},
    alias: cardTTBny3OqDpMeta?.alias || [],
    redirect: cardTTBny3OqDpMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepayualXTiugqnMeta?.name ?? "account-deposit-codepay",
    path: codepayualXTiugqnMeta?.path ?? "deposit/codepay",
    meta: codepayualXTiugqnMeta || {},
    alias: codepayualXTiugqnMeta?.alias || [],
    redirect: codepayualXTiugqnMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay21QGVRIE6SmMeta?.name ?? "account-deposit-codepay2",
    path: codepay21QGVRIE6SmMeta?.path ?? "deposit/codepay2",
    meta: codepay21QGVRIE6SmMeta || {},
    alias: codepay21QGVRIE6SmMeta?.alias || [],
    redirect: codepay21QGVRIE6SmMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: cryptoH5ziRjjIRDMeta?.name ?? "account-deposit-crypto",
    path: cryptoH5ziRjjIRDMeta?.path ?? "deposit/crypto",
    meta: cryptoH5ziRjjIRDMeta || {},
    alias: cryptoH5ziRjjIRDMeta?.alias || [],
    redirect: cryptoH5ziRjjIRDMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: ewalletpl4JkUNV6FMeta?.name ?? "account-deposit-ewallet",
    path: ewalletpl4JkUNV6FMeta?.path ?? "deposit/ewallet",
    meta: ewalletpl4JkUNV6FMeta || {},
    alias: ewalletpl4JkUNV6FMeta?.alias || [],
    redirect: ewalletpl4JkUNV6FMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/deposit/ewallet.vue").then(m => m.default || m)
  },
  {
    name: historyFm5QYozFNwMeta?.name ?? "account-history",
    path: historyFm5QYozFNwMeta?.path ?? "history",
    children: [
  {
    name: betNvkUNGdbLLMeta?.name ?? "account-history-bet",
    path: betNvkUNGdbLLMeta?.path ?? "bet",
    meta: betNvkUNGdbLLMeta || {},
    alias: betNvkUNGdbLLMeta?.alias || [],
    redirect: betNvkUNGdbLLMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transactionobWtmZ0AFHMeta?.name ?? "account-history-transaction",
    path: transactionobWtmZ0AFHMeta?.path ?? "transaction",
    meta: transactionobWtmZ0AFHMeta || {},
    alias: transactionobWtmZ0AFHMeta?.alias || [],
    redirect: transactionobWtmZ0AFHMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyFm5QYozFNwMeta || {},
    alias: historyFm5QYozFNwMeta?.alias || [],
    redirect: historyFm5QYozFNwMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profileiXLG9QNOxKMeta?.name ?? "account-profile",
    path: profileiXLG9QNOxKMeta?.path ?? "profile",
    meta: profileiXLG9QNOxKMeta || {},
    alias: profileiXLG9QNOxKMeta?.alias || [],
    redirect: profileiXLG9QNOxKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: trading_45p2p4xARsxcTMOMeta?.name ?? "account-trading-p2p",
    path: trading_45p2p4xARsxcTMOMeta?.path ?? "trading-p2p",
    meta: trading_45p2p4xARsxcTMOMeta || {},
    alias: trading_45p2p4xARsxcTMOMeta?.alias || [],
    redirect: trading_45p2p4xARsxcTMOMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/trading-p2p.vue").then(m => m.default || m)
  },
  {
    name: bankHnkQBQXRQdMeta?.name ?? "account-withdraw-bank",
    path: bankHnkQBQXRQdMeta?.path ?? "withdraw/bank",
    meta: bankHnkQBQXRQdMeta || {},
    alias: bankHnkQBQXRQdMeta?.alias || [],
    redirect: bankHnkQBQXRQdMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: card49XBV19KXyMeta?.name ?? "account-withdraw-card",
    path: card49XBV19KXyMeta?.path ?? "withdraw/card",
    meta: card49XBV19KXyMeta || {},
    alias: card49XBV19KXyMeta?.alias || [],
    redirect: card49XBV19KXyMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: cryptoeTYVEHFM2oMeta?.name ?? "account-withdraw-crypto",
    path: cryptoeTYVEHFM2oMeta?.path ?? "withdraw/crypto",
    meta: cryptoeTYVEHFM2oMeta || {},
    alias: cryptoeTYVEHFM2oMeta?.alias || [],
    redirect: cryptoeTYVEHFM2oMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountINx95OOlSoMeta || {},
    alias: accountINx95OOlSoMeta?.alias || [],
    redirect: accountINx95OOlSoMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45button1XFZq5UuMuMeta?.name ?? "dev-base-button",
    path: base_45button1XFZq5UuMuMeta?.path ?? "/dev/base-button",
    meta: base_45button1XFZq5UuMuMeta || {},
    alias: base_45button1XFZq5UuMuMeta?.alias || [],
    redirect: base_45button1XFZq5UuMuMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downVbGBaBBmviMeta?.name ?? "dev-base-count-down",
    path: base_45count_45downVbGBaBBmviMeta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downVbGBaBBmviMeta || {},
    alias: base_45count_45downVbGBaBBmviMeta?.alias || [],
    redirect: base_45count_45downVbGBaBBmviMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radioSBjeRiN3IbMeta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radioSBjeRiN3IbMeta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radioSBjeRiN3IbMeta || {},
    alias: base_45dropdown_45radioSBjeRiN3IbMeta?.alias || [],
    redirect: base_45dropdown_45radioSBjeRiN3IbMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgYtktoPHe2KMeta?.name ?? "dev-base-img",
    path: base_45imgYtktoPHe2KMeta?.path ?? "/dev/base-img",
    meta: base_45imgYtktoPHe2KMeta || {},
    alias: base_45imgYtktoPHe2KMeta?.alias || [],
    redirect: base_45imgYtktoPHe2KMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputMKJm0uRzCAMeta?.name ?? "dev-base-input",
    path: base_45inputMKJm0uRzCAMeta?.path ?? "/dev/base-input",
    meta: base_45inputMKJm0uRzCAMeta || {},
    alias: base_45inputMKJm0uRzCAMeta?.alias || [],
    redirect: base_45inputMKJm0uRzCAMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loadingbkISna7Gq2Meta?.name ?? "dev-base-loading",
    path: base_45loadingbkISna7Gq2Meta?.path ?? "/dev/base-loading",
    meta: base_45loadingbkISna7Gq2Meta || {},
    alias: base_45loadingbkISna7Gq2Meta?.alias || [],
    redirect: base_45loadingbkISna7Gq2Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modalzsuPio39TVMeta?.name ?? "dev-base-modal",
    path: base_45modalzsuPio39TVMeta?.path ?? "/dev/base-modal",
    meta: base_45modalzsuPio39TVMeta || {},
    alias: base_45modalzsuPio39TVMeta?.alias || [],
    redirect: base_45modalzsuPio39TVMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputga8YsbxtxFMeta?.name ?? "dev-base-money-input",
    path: base_45money_45inputga8YsbxtxFMeta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputga8YsbxtxFMeta || {},
    alias: base_45money_45inputga8YsbxtxFMeta?.alias || [],
    redirect: base_45money_45inputga8YsbxtxFMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationEnVedw7liuMeta?.name ?? "dev-base-pagination",
    path: base_45paginationEnVedw7liuMeta?.path ?? "/dev/base-pagination",
    meta: base_45paginationEnVedw7liuMeta || {},
    alias: base_45paginationEnVedw7liuMeta?.alias || [],
    redirect: base_45paginationEnVedw7liuMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45bankss2Kkl5cIGMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45bankss2Kkl5cIGMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45bankss2Kkl5cIGMeta || {},
    alias: base_45select_45bankss2Kkl5cIGMeta?.alias || [],
    redirect: base_45select_45bankss2Kkl5cIGMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemSQ9BlR2SZ2Meta?.name ?? "dev-game-item",
    path: game_45itemSQ9BlR2SZ2Meta?.path ?? "/dev/game-item",
    meta: game_45itemSQ9BlR2SZ2Meta || {},
    alias: game_45itemSQ9BlR2SZ2Meta?.alias || [],
    redirect: game_45itemSQ9BlR2SZ2Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: indexiGFlsWvYiKMeta?.name ?? "dev",
    path: indexiGFlsWvYiKMeta?.path ?? "/dev",
    meta: indexiGFlsWvYiKMeta || {},
    alias: indexiGFlsWvYiKMeta?.alias || [],
    redirect: indexiGFlsWvYiKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gamevqNmkVqUS3Meta?.name ?? "game",
    path: gamevqNmkVqUS3Meta?.path ?? "/game",
    children: [
  {
    name: _91type_93hW8m9vxsKzMeta?.name ?? "game-type",
    path: _91type_93hW8m9vxsKzMeta?.path ?? ":type",
    meta: _91type_93hW8m9vxsKzMeta || {},
    alias: _91type_93hW8m9vxsKzMeta?.alias || [],
    redirect: _91type_93hW8m9vxsKzMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gamevqNmkVqUS3Meta || {},
    alias: gamevqNmkVqUS3Meta?.alias || [],
    redirect: gamevqNmkVqUS3Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieu11wczK5PePMeta?.name ?? "gioi-thieu",
    path: gioi_45thieu11wczK5PePMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93ERdWbowrbxMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93ERdWbowrbxMeta?.path ?? ":alias",
    meta: _91alias_93ERdWbowrbxMeta || {},
    alias: _91alias_93ERdWbowrbxMeta?.alias || [],
    redirect: _91alias_93ERdWbowrbxMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieu11wczK5PePMeta || {},
    alias: gioi_45thieu11wczK5PePMeta?.alias || [],
    redirect: gioi_45thieu11wczK5PePMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45danwqSD1eMPDBMeta?.name ?? "huong-dan",
    path: huong_45danwqSD1eMPDBMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93T1kbZUs5hYMeta?.name ?? "huong-dan-alias",
    path: _91alias_93T1kbZUs5hYMeta?.path ?? ":alias",
    meta: _91alias_93T1kbZUs5hYMeta || {},
    alias: _91alias_93T1kbZUs5hYMeta?.alias || [],
    redirect: _91alias_93T1kbZUs5hYMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45danwqSD1eMPDBMeta || {},
    alias: huong_45danwqSD1eMPDBMeta?.alias || [],
    redirect: huong_45danwqSD1eMPDBMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexBEl7wtRUx0Meta?.name ?? "index",
    path: indexBEl7wtRUx0Meta?.path ?? "/",
    meta: indexBEl7wtRUx0Meta || {},
    alias: indexBEl7wtRUx0Meta?.alias || [],
    redirect: indexBEl7wtRUx0Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45maiFDppUDrR2wMeta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_93G8vn1DPvBQMeta?.name ?? "khuyen-mai-id",
    path: _91id_93G8vn1DPvBQMeta?.path ?? ":id",
    meta: _91id_93G8vn1DPvBQMeta || {},
    alias: _91id_93G8vn1DPvBQMeta?.alias || [],
    redirect: _91id_93G8vn1DPvBQMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: indexX9X8ZUZyVpMeta?.name ?? "khuyen-mai",
    path: indexX9X8ZUZyVpMeta?.path ?? "",
    meta: indexX9X8ZUZyVpMeta || {},
    alias: indexX9X8ZUZyVpMeta?.alias || [],
    redirect: indexX9X8ZUZyVpMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93K2UFsyZH5MMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93K2UFsyZH5MMeta?.path ?? "the-loai/:id",
    meta: _91id_93K2UFsyZH5MMeta || {},
    alias: _91id_93K2UFsyZH5MMeta?.alias || [],
    redirect: _91id_93K2UFsyZH5MMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: vipM467blDCVtMeta?.name ?? "khuyen-mai-vip",
    path: vipM467blDCVtMeta?.path ?? "vip",
    meta: vipM467blDCVtMeta || {},
    alias: vipM467blDCVtMeta?.alias || [],
    redirect: vipM467blDCVtMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  }
],
    name: khuyen_45maiFDppUDrR2wMeta?.name ?? undefined,
    meta: khuyen_45maiFDppUDrR2wMeta || {},
    alias: khuyen_45maiFDppUDrR2wMeta?.alias || [],
    redirect: khuyen_45maiFDppUDrR2wMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    path: livecasino4clsLugEvXMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93LrfUv1xYRJMeta?.name ?? "livecasino-type",
    path: _91type_93LrfUv1xYRJMeta?.path ?? ":type",
    meta: _91type_93LrfUv1xYRJMeta || {},
    alias: _91type_93LrfUv1xYRJMeta?.alias || [],
    redirect: _91type_93LrfUv1xYRJMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/livecasino/[type].vue").then(m => m.default || m)
  },
  {
    name: indexrV81sYWGrOMeta?.name ?? "livecasino",
    path: indexrV81sYWGrOMeta?.path ?? "",
    meta: indexrV81sYWGrOMeta || {},
    alias: indexrV81sYWGrOMeta?.alias || [],
    redirect: indexrV81sYWGrOMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/livecasino/index.vue").then(m => m.default || m)
  }
],
    name: livecasino4clsLugEvXMeta?.name ?? undefined,
    meta: livecasino4clsLugEvXMeta || {},
    alias: livecasino4clsLugEvXMeta?.alias || [],
    redirect: livecasino4clsLugEvXMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordFkmZWsQX6eMeta?.name ?? "reset-password",
    path: reset_45passwordFkmZWsQX6eMeta?.path ?? "/reset-password",
    meta: reset_45passwordFkmZWsQX6eMeta || {},
    alias: reset_45passwordFkmZWsQX6eMeta?.alias || [],
    redirect: reset_45passwordFkmZWsQX6eMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RytHW1kTvTMeta?.name ?? "the-thao-id",
    path: _91id_93RytHW1kTvTMeta?.path ?? "/the-thao/:id",
    meta: _91id_93RytHW1kTvTMeta || {},
    alias: _91id_93RytHW1kTvTMeta?.alias || [],
    redirect: _91id_93RytHW1kTvTMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdQf3UM2GkAMeta?.name ?? "the-thao",
    path: indexdQf3UM2GkAMeta?.path ?? "/the-thao",
    meta: indexdQf3UM2GkAMeta || {},
    alias: indexdQf3UM2GkAMeta?.alias || [],
    redirect: indexdQf3UM2GkAMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: indexQGdGM4CSFJMeta?.name ?? "the-thao-lich-thi-dau-chi-tiet",
    path: indexQGdGM4CSFJMeta?.path ?? "/the-thao/lich-thi-dau/chi-tiet",
    meta: indexQGdGM4CSFJMeta || {},
    alias: indexQGdGM4CSFJMeta?.alias || [],
    redirect: indexQGdGM4CSFJMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/the-thao/lich-thi-dau/chi-tiet/index.vue").then(m => m.default || m)
  },
  {
    name: indexmrukvZ6eOXMeta?.name ?? "the-thao-lich-thi-dau",
    path: indexmrukvZ6eOXMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: indexmrukvZ6eOXMeta || {},
    alias: indexmrukvZ6eOXMeta?.alias || [],
    redirect: indexmrukvZ6eOXMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tuceZ9NeqnDZVMeta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_93MyKMMxJJv7Meta?.name ?? "tin-tuc-alias",
    path: _91alias_93MyKMMxJJv7Meta?.path ?? ":alias",
    meta: _91alias_93MyKMMxJJv7Meta || {},
    alias: _91alias_93MyKMMxJJv7Meta?.alias || [],
    redirect: _91alias_93MyKMMxJJv7Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: indexCHLm3jKtm6Meta?.name ?? "tin-tuc",
    path: indexCHLm3jKtm6Meta?.path ?? "",
    meta: indexCHLm3jKtm6Meta || {},
    alias: indexCHLm3jKtm6Meta?.alias || [],
    redirect: indexCHLm3jKtm6Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93TsVPEZwEHrMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93TsVPEZwEHrMeta?.path ?? "the-loai/:Slug",
    meta: _91Slug_93TsVPEZwEHrMeta || {},
    alias: _91Slug_93TsVPEZwEHrMeta?.alias || [],
    redirect: _91Slug_93TsVPEZwEHrMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tuceZ9NeqnDZVMeta?.name ?? undefined,
    meta: tin_45tuceZ9NeqnDZVMeta || {},
    alias: tin_45tuceZ9NeqnDZVMeta?.alias || [],
    redirect: tin_45tuceZ9NeqnDZVMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45mailTQKLjoi1V7Meta?.name ?? "verify-mail",
    path: verify_45mailTQKLjoi1V7Meta?.path ?? "/verify-mail",
    meta: verify_45mailTQKLjoi1V7Meta || {},
    alias: verify_45mailTQKLjoi1V7Meta?.alias || [],
    redirect: verify_45mailTQKLjoi1V7Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps26-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]