import { Router } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  ;(nuxtApp.$router as Router).options.scrollBehavior = (to, from) => {
    if (to.hash) {
      return {
        el: to.hash
      }
    }

    if (preventScrollByPopup(to, from)) {
      return
    }

    return { top: 0 }
  }
})

const preventScrollByPopup = (to: any, from: any) => {
  if (to.query.popup || from.query.popup) {
    return true
  }
  return false
}
